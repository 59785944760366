import React from 'react'

export default() => {
    return (
        <>
         
  {/* ======= Services Section ======= */}
<section id="services" className="services section-bg ">
  <div className="container" data-aos="fade-right">
    <div className="section-title">
      <h2>Services</h2>
      <p>
      Notre service à la clientèle est disponible quotidiennement pour répondre à vos questions, vous donner des prix, faire le suivi de vos demandes, vous informer sur nos produits ou vous fournir toute autre information. 
      </p>
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="icon-box" data-aos="fade-left" data-aos-delay={20}>
          <i className="bi bi-key" />
          <h4><a href="#">CLÉ EN MAIN</a></h4>
          <p>
          Nos vous fournissons des solution industriel clé en main.
          </p>
        </div>
      </div>
      <div className="col-md-6 mt-4 mt-md-0">
        <div className="icon-box" data-aos="fade-right" data-aos-delay={20}>
          <i className="bi bi-truck" />
          <h4><a href="#">SAV</a></h4>
          <p>
          On vous assure un service après-vente rapide et disponible 24/7.
          </p>
        </div>
      </div>
      <div className="col-md-6 mt-4 mt-md-0">
        <div className="icon-box" data-aos="fade-left" data-aos-delay={20}>
          <i className="bi bi-wrench"/>
          <h4><a href="#">MAINTENANCE</a></h4>
          <p>
          Nous réparons vos machines (même celles de nos concurrents), où que vous soyez.
          </p>
        </div>
      </div>
      <div className="col-md-6 mt-4 mt-md-0">
        <div className="icon-box" data-aos="fade-up" data-aos-delay={20}>
          <i className="bi bi-binoculars" />
          <h4><a href="#">CONTROLE</a></h4>
          <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
        </div>
      </div>
      <div className="col-md-6 mt-4 mt-md-0">
        <div className="icon-box" data-aos="fade-up" data-aos-delay={20}>
          <i className="bi bi-laptop" />
          <h4><a href="#">INTÉGRATION</a></h4>
          <p>
          L'intégration des équipements sur vos chaînes de production se fait par notre équipe.
          </p>
        </div>
      </div>
      <div className="col-md-6 mt-4 mt-md-0">
        <div className="icon-box" data-aos="fade-up" data-aos-delay={20}>
          <i className="bi bi-headset" />
          <h4><a href="#">ASSISTANCE</a></h4>
          <p>
          Nous vous accompagnons à chaque étape d'installation.
          </p>
        </div>
      </div>
     
    </div>
  </div>
</section>{/* End Services Section */}

   
        </>
    )
}

