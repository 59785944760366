import React, { useEffect, useRef, useState } from 'react';
import logo from '../../assets/img/logo.png'
import './index.css'

export default () => {

    const [togle, settogle] = useState(false);

    const [location, setlocation] = useState('acceuil')
    const [width, setwidth] = useState(0)

    const stageCanvasRef = useRef(null);

    const scroll = (id) => {
        const titleElement = document.getElementById(id)
        titleElement.scrollIntoView({ behavior: 'smooth' })
        setlocation(id)
        settogle(width < 600 && !togle)
    }

    useEffect(() => {

        // The 'current' property contains info of the reference:
        // align, title, ... , width, height, etc.
        if (stageCanvasRef.current) {

            let height = stageCanvasRef.current.offsetHeight;
            let width = stageCanvasRef.current.offsetWidth;
            console.log(width);
            setwidth(width)
        }

    }, [stageCanvasRef]);

    return (

        <>
            <header ref={stageCanvasRef} id="header" className="fixed-top d-flex align-items-center">
                <div className="container d-flex align-items-center">
                    <h1 className="logo me-auto">
                        <a href="index.html" style={{ display: 'flex', alignItems: 'center' }} >
                            <img style={{ marginRight: "10px" }} src={logo} />
                            <p style={{ marginTop: "12px" }}  >CODAPRINT</p>
                        </a>
                    </h1>
                    <nav id="navbar" className={togle ? "navbar order-last order-lg-0 navbar-mobile " : "navbar order-last order-lg-0  "}>
                        <ul>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'acceuil' ? 'active_link' : ''} scrollto`} onClick={() => scroll('acceuil')} >ACCEUIL</a></li>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'about' ? 'active_link' : ''} scrollto`} onClick={() => scroll('about')} >A PROPOS</a></li>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'tabs' ? 'active_link' : ''} scrollto`} onClick={() => scroll('tabs')}  >PRODUITS</a></li>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'services' ? 'active_link' : ''} scrollto`} onClick={() => scroll('services')} >SERVICES</a></li>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'portfolio' ? 'active_link' : ''} scrollto`} onClick={() => scroll('portfolio')} >NOS INSTALLATIONS</a></li>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'team' ? 'active_link' : ''} scrollto`} onClick={() => scroll('team')}  >EQUIPES</a></li>
                            <li style={{ display: 'flex', justifyContent: 'center' }} ><a className={`nav-link ${location === 'contact' ? 'active_link' : ''} scrollto`} onClick={() => scroll('contact')} >CONTACT</a></li>
                        </ul>
                        <i id='closeicon' onClick={() => settogle(!togle)} className={togle ? "bi  bi-x mobile-nav-toggle " : "bi  bi-list mobile-nav-toggle"} />
                    </nav >
                </div>
            </header>
        </>
    )
}