import React from 'react'

export default () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>CODAPRINT<span>.</span></h3>
                <p>
                  60 Rue El kawther El mourouj 6<br />
                  2074, Ben Arous <br />
                  Tunisie <br /><br />
                  <strong>Phone: </strong><span  onClick={() => window.open('tel:+21695666647')}>+216 95 66 66 47</span> <br />
                  <strong>Email: </strong> codaprint@codaprint.com.tn<br />
                </p>
              </div>
              <div className="col-lg-2 col-md-6 footer-links">
                <h4>LIENS</h4>
                <ul>
                  <li><i className="bx bx-chevron-right" /> <a href="#acceuil">Acceuil</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#about">à propos</a></li>
                  <li><i className="bx bx-chevron-right" /> <a href="#services">Services</a></li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-newsletter">
                <h4>RESTEZ AU COURANT DE NOS ACTU</h4>
                <p>Inscrivez-vous et recever notre nouveaux actualité</p>
                <form action method="post">
                  <input type="email" name="email" /><input type="submit" defaultValue="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              © Copyright <strong><span>CODAPRINT</span></strong>. All Rights Reserved
            </div>

          </div>
          <div className="social-links text-center text-md-end pt-3 pt-md-0">
            <a href="https://www.facebook.com/codaprint.com.tn" className="facebook"><i className="bx bxl-facebook" /></a>
            <a href="https://www.youtube.com/channel/UCOOO428LRxgxCjRMQ6mih2w?fbclid=IwAR1UZH6n769xrLo0v5kJ4KixVRDKnBHqDi2NNlyb2NpqPI_y_CZRqx1uh1s" className="facebook"><i className="bx bxl-youtube" /></a>
            <a href="https://www.linkedin.com/company/codaprint/?fbclid=IwAR0kM1nwlGEakHL92Wqu5uywJNuQo-sR7kR6O_bRdIoGMdsenSKW3f4PasM" className="linkedin"><i className="bx bxl-linkedin" /></a>
          </div>
        </div>
      </footer>{/* End Footer */}

    </>
  )
}

