import React from 'react'

export default () => {
  return (
    <>
      {/* ======= About Section ======= */}
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up">
          <div className="row no-gutters">
            <div className="content col-xl-5 d-flex align-items-stretch">
              <div className="content">
                <h3>QUI SOMMES-NOUS ?</h3>
                <p>
                CODAPRINT, est spécialisée dans la fourniture de l'équipement industriel notamment les imprimantes (Dateurs) et les moteurs électrique et les palans élévateurs.<p/> Depuis le lancement, CODAPRINT représente des marques reconnues à l'échelle internationale par leur robustesse et leurs performances incontestables                 </p>
               {/*  <a href="#" className="about-btn"><span>About us</span> <i className="bx bx-chevron-right" /></a> */}
              </div>
            </div>
            <div className="col-xl-7 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-md-6 icon-box" data-aos="fade-up" >
                    <i className="bi bi-lightbulb" />
                    <h4>Des Solutions</h4>
                    <p>
                    Des solutions d'identification sur mesure d'un seul et même fournisseur font partie de notre image de fournisseur complet.

                    </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up">
                    <i className="bx bx-cube-alt" />
                    <h4>Environnement </h4>
                    <p>
                    Nous sommes non seulement le premier choix dans l'environnement industriel difficile des usines de transformation de l'acier, du bois, du caoutchouc et du plastique, mais nous maîtrisons également les tâches de codage et de sérialisation les plus exigeantes, par exemple dans l'industrie pharmaceutique et alimentaire.                      </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up">
                    <i className="bi bi-brightness-high" />
                    <h4>Innovante</h4>
                    <p>
                    Nous vous fournissons une solution innovante, des performances hors normes et haute compétence technique: moteur électrique moto-réducteur variateur et palan élévateur. 
                    </p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" d>
                    <i className="bx bx-shield" />
                    <h4>Protection</h4>
                    <p>
                      Dans le cadre de developpement idustriel dans le monde on metre tout d'abord la protection comme er objectif pour notre societé                     </p>
                  </div>
                </div>
              </div>{/* End .content*/}
            </div>
          </div>
        </div>
      </section>{/* End About Section */}


    </>
  )
}