import React from 'react';
import smart from './smart.pdf'
export default () => {
    return (
        <>
            <section id="acceuil" class="d-flex align-items-center">
                <div class="container" data-aos="zoom-out" data-aos-delay="20">
                    <div class="row">
                        <div class="col-xl-6">
                            <h1>CODAPRINT</h1>
                            <h2>Nous fournissons des solutions pour votre entreprise!</h2>
                            <a href={smart} title="pdf" download class="btn-get-started scrollto">Telecharger nos catalogues</a>

                            <div className="App">
                            </div>
                        </div>

                    </div>
                </div>

            </section>
        </>
    )
}