
import React from "react";
import cimenterie from '../../assets/img/cimenterie.jpg'
import carthage from '../../assets/img/carthage.png'
import saiph from '../../assets/img/saiph.jpeg'
import unimed from '../../assets/img/unimed.png'
import stip from '../../assets/img/stip.png'
import sotacib from '../../assets/img/sotacib.png'
import './index.css'
import logo from './logo512.png'

export default function App() {

    const logos = [cimenterie, carthage, saiph, unimed, sotacib, stip]

    return (
        <>
            <div className="p-3">
                <h3 style={{marginLeft:'25px'}} > Nos Clients</h3>
                <div className="swiper row d-flex">
                    {
                        logos.map(l => {
                            return (
                                <>
                                    <div className="logo_container col-sm-12 col-lg-2 ">
                                        <img src={l} alt="" />
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </div>

            
        </>
    )
}