import React, { useEffect } from 'react'
import About from './components/About';
import Count from './components/Count';
import Tab from './components/Tab';
import Service from './components/Service';
import Portfolio from './components/Portfolio';
import Pricing from './components/Pricing';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';

import Hero from './components/Hero';
import Navbar from "./components/Navbar";
import Partenaires from './components/Partenaires';

import ReactGa from 'react-ga'

function App() {



  useEffect(() => {
    ReactGa.initialize('UA-214998229-1')
    ReactGa.pageview('/')
  }, [])

  return (
    <div>
      <Navbar />
      <Hero />

      <main id="main">
        <Partenaires />
        <About />

        <Tab />
        <Service />
        <Portfolio />


        <Team />
        <Contact />
        <Footer />

      </main>
    </div>
  );
}

export default App;
