import React from 'react'

export default () => {
  return (
    <>
      {/* ======= Team Section ======= */}
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Equipes</h2>
            <p>CODAPRINT, est spécialisée dans la fourniture de l'équipement industriel notamment les imprimantes (Dateurs) et les moteurs électrique et les palans élévateurs. Depuis le lancement, CODAPRINT représente des marques reconnues à l'échelle internationale par leur robustesse et leurs performances incontestables </p>
          </div>
          <div className="row d-flex  justify-content-center">
            <div className="col-lg-3 col-md-6" >
              <div className="member " data-aos="fade-up" data-aos-delay={20}>
                <div className="member-img" >
                  <img src="assets/img/team/bilel.jpg" className="img-fluid" alt />
                  <div className="social ">
                    <a href='https://www.linkedin.com/company/codaprint/?fbclid=IwAR0kM1nwlGEakHL92Wqu5uywJNuQo-sR7kR6O_bRdIoGMdsenSKW3f4PasM'><i className="bi bi-linkedin" /></a>
                  </div>
                </div>
                <div className="member-info">
                  <h4>Bilel Rayechi</h4>
                  <span>Manager</span>
                </div>
              </div>
            </div>




          </div>
        </div>
      </section>{/* End Team Section */}


    </>
  )
}

