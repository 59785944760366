import React from 'react'
import { useState } from 'react'
export default () => {



  return (
    <>
      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Contact</h2>
            <p>Nous restons à votre disposition pour vous assister.</p>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay={10}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-box">
                    <i className="bx bx-map" />
                    <h3> Address</h3>
                    <p>60 Rue El kawther El mourouj 6, 2074, Ben Arous, Tunisie</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i className="bx bx-envelope" />
                    <h3>Email</h3>
                    <p onClick={() => window.open('mailto:codaprint@codaprint.com.tn')} >codaprint@codaprint.com.tn</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4">
                    <i onClick={() => window.open('tel:+21695666647')} className="bx bx-phone-call" />
                    <h3>Tél</h3>
                    <a style={{ cursor: 'pointer' }} onClick={() => window.open('tel:+21695666647')} > +216 95 66 66 47</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                <div className="row">
                  <div className="col form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder=" Nom" required />
                  </div>
                  <div className="col form-group">
                    <input type="email" className="form-control" name="email" id="email" placeholder=" Email" required />
                  </div>
                  <div className="col form-group">
                    <input type="text" name="number" className="form-control" id="tel" placeholder=" Numero Tél" required />
                  </div>
                </div>
                <div className="form-group">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Sujet" required />
                </div>
                <div className="form-group">
                  <textarea className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" />
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Envoyer</button></div>
              </form>
            </div>
          </div>
        </div>
      </section>{/* End Contact Section */}


    </>
  )
}

