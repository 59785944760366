import React from 'react'
import img1 from './turck/img1.png'
import turk from './turk.png'
import img2 from './turck/img2.png'

import logofimet from './fimet/logofimet.png'
import logomisa from './misa/logomisa.png'
import reajet from './reajet.png'
import zenjet from './zenjet.png'
import macell from './macell.png'
import elesa from './elesa.png'
import fuher from './fuher.png'
import './index.css'

import valentin from './valentin.png'
export default () => {
  return (
    <>
      {/* ======= Tabs Section ======= */}
      <section id="tabs" className="tabs" ><br /><br />
        <div className="container" data-aos="fade-up">
          <ul className="nav nav-tabs row d-flex">
            <li className="nav-item col-3">
              <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-3">
                <i className="ri-qr-code-line" />
                <h4 className="d-none d-lg-block">SOLUTION D'IDENTIFICATION</h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                <i className="ri-robot-line" />
                <h4 className="d-none d-lg-block">SOLUTION AUTOMATISME</h4>
              </a>
            </li>
            <li className="nav-item col-3">
              <a className="nav-link " data-bs-toggle="tab" data-bs-target="#tab-1">
                <i className="ri-lightbulb-flash-line" />
                <h4 className="d-none d-lg-block">NOS PRODUIT ELECTRIQUE</h4>
              </a>
            </li>

            <li className="nav-item col-3">
              <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
                <i className="ri-store-line" />
                <h4 className="d-none d-lg-block">PRODUIT ACCESSOIRES </h4>
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div className="tab-pane " id="tab-1">
              <div className="row">
                <h3>Moteur électrique et variateur</h3>
                <h4 className='mt-3' >Moteur électrique, Moto-réducteur, Variateurs et palan élévateur </h4>
                <div className="col-sm-12 col-lg-6 mt-3" data-aos="fade-up" data-aos-delay={20}>

                  <ul>  <li><i className="ri-check-double-line" /> FIMET </li></ul>
                  <img src={logofimet} style={{ height: "75px", width: "100px" }} />

                  <p className='mt-3' >
                    FIMET (Italie Moteurs électriques usine Turin), c'est la longue histoire de l'ingénierie italienne. Fondée en 1910 à Turin par la famille Manassero, en 2010, elle a accumulé 100 ans d'activité industrielle. Dans les années 70 FIMET a déplacé son usine de fabrication à Bra (Cn). La propriété de la société est maintenant dans sa quatrième génération, toujours sous la direction d'une seule famille depuis plus de 100 ans.
                  </p>
                </div>
                <div className="col-sm-12 col-lg-6 mt-3" data-aos="fade-up" data-aos-delay={20}>
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/76RvuGun49Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                </div>

              </div>

              <div className="row">
                <div className="col-sm-12 col-lg-6 mt-3">
                  <ul>  <li><i className="ri-check-double-line" /> MISA </li></ul>
                  <img src={logomisa} style={{ height: "50px", width: "100px", }} />

                  <p className='mt-3'>
                    MISIA fabrique une vaste gamme de palans depuis plus de 35 ans.
                    Plus de 100 000 unités de câbles métalliques ont été vendues dans le monde et sont opérationnelles dans tous les secteurs industriels.
                    La gamme de la série XM se compose de 11 tailles avec des capacités comprises entre 1.000 Kg et 50.000 Kg.
                    Les unités de levage sont disponibles dans différentes configurations: monorail à faible hauteur libre, monorail standard à hauteur libre, monté sur pied (fixe ou suspendu), unités à double rail «crabe» (supportées ou suspendues).</p>

                </div>
                <div className="col-sm-12 col-lg-6 mt-3">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/c6D4EIQUwsQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="tab-2">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <h3>SOLUTION AUTOMATISME</h3>
                  <ul>  <li><i className="ri-check-double-line" /> TURCK </li></ul>
                  <img src={turk} style={{ height: "200px", width: "150px" }} />
                  <p>
                    Turck est votre partenaire mondial pour l'automatisation des usines, des processus et de la logistique dans de nombreux secteurs. Avec nos solutions en réseau numérique pour des systèmes d'automatisation efficaces, nous sommes l'un des pionniers de l'Industrie 4.0 et de l'IIoT. En tant que spécialiste de la technologie des capteurs intelligents et de l'automatisation décentralisée, Turck apporte de l'intelligence aux machines et garantit une détection, une transmission et un traitement fiables des données de production pertinentes - du capteur au cloud - non seulement avec des solutions d'E / S robustes en IP67, mais également avec l'utilisateur. logiciels et services conviviaux.

                    Les solutions d'automatisation de Turck augmentent la disponibilité et l'efficacité des machines et des installations dans de nombreuses industries et applications, de l'industrie alimentaire, pharmaceutique ou chimique à l'industrie automobile et aux machines mobiles à l'intralogistique et à l'emballage. La connaissance des applications spécifiques à l'industrie acquise grâce à un échange intensif avec les clients, associée au développement et à la production électroniques au plus haut niveau, fournit des solutions optimales pour les tâches d'automatisation des clients.

                    Plus de 4 650 employés dans le monde développent, fabriquent et vendent des produits et des solutions dans les domaines des capteurs, bus de terrain, contrôle, cloud, technologie de connexion et d'interface ainsi que HMI et RFID. Notre réseau mondial de production et de vente comprend plus de 30 filiales ainsi que des agences dans 60 autres pays. Des installations de production à la pointe de la technologie en Allemagne, en Suisse, en Pologne, aux États-Unis, au Mexique et en Chine nous permettent en tant qu'entreprise familiale indépendante d'être flexible et de répondre rapidement aux exigences des marchés locaux à tout moment.
                  </p>

                </div>
                <div className="col-sm-12 col-lg-6">
                  <br /><br />
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/-BwCc8HCHyI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <div className="tab-pane active show" id="tab-3">

              <h3>SOLUTION D'IDENTIFICATION</h3>

              <div className="row solution_identification ">

                <div className="col-sm-12 col-md-6">
                  <h5> <i className="ri-check-double-line" />  Solutions de marquage pour l'industrie</h5>
                  <img src={reajet} style={{
                    height: "75px",
                    width: "100px"
                  }} />
                  <p>
                    Avec les systèmes d'identification de <b>REA JET</b>, ajoutez des inscriptions et des marques à vos
                    textes, codes à barres linéaires, codes Data Matrix, logos, informations produit, dates de péremption,
                    numéros de chargeur, données de traçage, contrôle qualité et protection contre les contrefaçons. REA JET
                    séduit avec son expertise de longue date et ses systèmes d'identification modernes, qu'ils soient à
                    encre, couleur ou laser.
                  </p>
                </div>

                <div className="col-sm-12 col-md-6 mt-3 ">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/Dle7YHDbzNo"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>

              </div>

              <div className="row solution_identification ">

                <div className="col-sm-12 col-md-6">
                  <h5> <i className="ri-check-double-line" />  les systèmes d'étiquetage</h5>
                  <img src={valentin} style={{
                    height: "75px", width: "150px"
                  }} />
                  <p>
                    Une excellente compétence dans le domaine des systèmes d'impression et de marquage, au service de
                    presque tous les secteurs industriels - voilà ce que propose <b>Carl Valentin GmbH</b>. L'usine
                    d'étiquettes créée en 1918 s'est développée pour devenir l'un des fabricants leader de systèmes
                    d'étiquetage - avec une présence sur les marchés mondiaux.

                    Nous développons, produisons et commercialisons des systèmes d'impression d'étiquettes et d'étiquetage
                    multifonctions comme modules stand-alone, modules d'installation, de blocs d'impression directs ou de
                    solutions spéciales qui créent des étiquettes de codes à barres et de textes en clair dans tous les
                    formats concevables selon le procédé thermique direct ou de transfert thermique - le tout complété par
                    un logiciel de création d'étiquettes convivial et une multitude d'accessoires adaptés
                  </p>
                </div>

                <div className="col-sm-12 col-md-6 mt-3 ">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/0FI9NFGHg9E"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>

              </div>

              <div className="row solution_identification ">

                <div className="col-sm-12 col-md-6">
                  <h5> <i className="ri-check-double-line" />   Marquage à jet d'encre TIJ</h5>
                  <img src={zenjet} style={{
                    height: "75px", width: "150px"
                  }} />
                  <p>
                    Avec la technologie <b>Zenjet TIJ</b>, nous n'utilisons plus aucun solvant.. L'imprimante Zenjet est
                    aussi propre et facile à utiliser qu'une imprimante de bureau; aucune contrainte comparé à l'utilisation
                    d'imprimantes à jet d'encre continu.

                    L'utilisation d'une imprimante Zenjet est aussi simple que celle d'une imprimante de bureau.<br /> Aucun
                    entretien ou de procédure Start/Stop nécessaire et une qualité d'impression toujours parfaite.
                  </p>
                </div>

                <div className="col-sm-12 col-md-6 mt-3 ">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/Ya0-u4GTs0Y"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
                </div>

              </div>

              <div className="row solution_identification ">

                <div className="col-sm-12 col-md-6">
                  <h5> <i className="ri-check-double-line" />  Solution de marquage TIJ</h5>
                  <img src={macell} style={{
                    height: "75px", width: "150px"
                  }} />
                  <p>
                    <b> Inter Mac</b> conçoit et fabrique des imprimantes à jet d'encre drop-on-demand & TIJ. Les
                    imprimantes sont utilisées pour marquer des nombres, des codes et des caractères sur des matériaux comme
                    l'acier, le papier et le bois pour tracer et identifier pour l'assurance qualité.
                  </p>
                </div>

                <div className="col-sm-12 col-md-6 mt-3 ">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/viUQMGAOp0o"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                  </iframe>
                </div>

              </div>

            </div>








            <div className="tab-pane" id="tab-4">
              <div className="row">
                <div className="col-sm-12 col-lg-6 mt-3">
                  <h3>PRODUIT ACCESSOIRES STANDARD</h3>

                  <ul>
                    <li><i className="ri-check-double-line" /> <b>ELESAGANTER</b></li>
                  </ul>
                  <img src={elesa} style={{ height: "50px", width: "250px" }} />
                  <p className='mt-3' >

                    ELESAGANTER est une joint-venture de vente mondiale qui propose la plus large
                    gamme de produits d'éléments de machine standard pour l'industrie mécanique. Des
                    produits hautement fiables garantissant une fonctionnalité parfaite avec un design
                    unique représentent le code de qualité distinctif ELESA + GANTER.
                  </p>

                </div>
                <div className="col-sm-12 col-lg-6 mt-3">

                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/hA6e1fKQJ-I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-lg-6 mt-3">
                  <ul>
                    <li><i className="ri-check-double-line" /> <b>FUHRMEISTER</b></li>
                  </ul>
                  <img src={fuher} style={{ height: "100px", width: "250px" }} />
                  <p>

                    Votre partenaire système pour armoires / armoires de commande • Climatisation d'armoires •
                    Composants d'armoires de commande • Armoire de commande et éclairage des machines.
                    Depuis plus de 25 ans, nous proposons à nos clients une large gamme de boîtiers et d'armoires de
                    commande, de dispositifs de refroidissement d'armoires de commande (unités de climatisation), de
                    refroidisseurs de liquide (refroidisseurs) et d'autres composants d'armoires de commande tels que des
                    éclairages d'armoires de commande, des radiateurs d'armoires de commande, des régulateurs de
                    température, des hygrostats, etc.
                  </p>
                </div>
                <div className="col-sm-12 col-lg-6 mt-3">

                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/R-3YcgjjVug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>



            </div>
          </div>
        </div>
      </section>{/* End Tabs Section */}


    </>
  )
}

